<template>
    <v-card flat>
        <v-card-title class="mb-2">
            <v-btn
                text
                outlined
                depressed
                color="secondary"
                @click="dialog = true"
            >
                <v-icon class="ml-1">mdi-plus-circle</v-icon>
                اضافة
            </v-btn>
            <v-divider class="mx-4 my-1" vertical> </v-divider>
            <v-spacer></v-spacer>
            <search-in-data-table />
        </v-card-title>

        <v-data-table
            :headers="headers"
            :items="groups"
            :search="search"
            :loading="loading"
            no-data-text="لا توجد بيانات"
            loading-text="جاري تحميل البيانات"
            hide-default-footer
            class="elevation-1"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
        >
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            @click="editItem(item)"
                            v-on="on"
                            fab
                            x-small
                            depressed
                        >
                            <v-icon color="secondary">
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>تعديل</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            @click="deleteItem(item.id)"
                            v-on="on"
                            fab
                            x-small
                            depressed
                            class="mr-2"
                        >
                            <v-icon color="error">
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>حذف</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="7"
            class="py-6"
        >
        </v-pagination>

        <v-dialog v-model="dialog" persistent max-width="50%">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-toolbar-title>
                            {{ formTitle }}
                        </v-toolbar-title>
                        <v-spacer />
                        <v-btn @click="close()" color="error">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        :disabled="
                                            editedItem.name == 'Administrtor'
                                        "
                                        persistent-hint
                                        :hint="
                                            editedItem.name == 'Administrtor'
                                                ? 'Can\'t Update This Group Name'
                                                : ''
                                        "
                                        v-model="editedItem.name"
                                        :rules="rules"
                                        outlined
                                        label="إسم المجموعة"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="editedItem.description"
                                        outlined
                                        label="التفاصيل"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-select
                                        v-model="groupRoles"
                                        label="الصلاحيات"
                                        :items="filterdRoles"
                                        outlined
                                        chips 
                                        multiple
                                        :menu-props="{
                                            maxHeight: '90%'
                                        }"
                                        item-text="roleCaption"
                                        item-value="id"
                                    >
                                        <template
                                            v-slot:item="{
                                                active,
                                                item,
                                                attrs,
                                                on
                                            }"
                                        >
                                            <span class="d-none">{{
                                                active
                                            }}</span>

                                            <v-list-item
                                                v-on="on"
                                                v-bind="attrs"
                                                #default="{ active }"
                                            >
                                                <v-list-item-action>
                                                    <v-checkbox
                                                        :input-value="active"
                                                    ></v-checkbox>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <span>
                                                            <!-- {{$t("roles." +item.roleCaption)}} -->
                                                            {{
                                                                item.roleCaption
                                                            }}
                                                        </span>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>

                                        <template
                                            v-slot:selection="{ item, index }"
                                        >
                                            <v-chip v-if="index === 0">
                                                <span>
                                                    {{ item.roleCaption }}
                                                    <!-- {{$t("roles." +item.roleCaption)}} -->
                                                </span>
                                            </v-chip>
                                            <span
                                                v-if="index === 1"
                                                class="grey--text text-caption"
                                            >
                                                (+{{
                                                    groupRoles.length -
                                                        1 +
                                                        " أخرين"
                                                }})
                                            </span>
                                        </template>
                                        <template v-slot:prepend-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <v-text-field
                                                            v-model="searchRole"
                                                            outlined
                                                            dense
                                                            hide-details
                                                            label="بحث"
                                                        ></v-text-field>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider></v-divider>
                                            <v-list-item ripple @click="toggle">
                                                <v-list-item-action>
                                                    <v-icon
                                                        :color="
                                                            groupRoles.length >
                                                            0
                                                                ? 'indigo darken-4'
                                                                : ''
                                                        "
                                                    >
                                                        {{ icon }}
                                                    </v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        إختيار الكل
                                                        <!-- {{ $t("selectAll") }} -->
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider class="mt-2"></v-divider>
                                        </template>
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions class="mt-12">
                        <v-btn
                            x-large
                            color="primary"
                            @click="save"
                            :disabled="!valid"
                        >
                            حفظ
                        </v-btn>
                        <v-btn color="red darken-1"  x-large @click="close" outlined>
                            الغاء
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-card>
</template>
<script>
import SearchInDataTable from "../components/SearchInDataTable.vue";
export default {
    components: { SearchInDataTable },
    data() {
        return {
            loading: false,
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            dialog: false,
            show: false,
            type: "success",
            message: "Completed Successfully",
            valid: true,
            dialogDelete: false,
            editedIndex: -1,
            search: "",
            searchRole: "",

            headers: [
                { text: "الاسم ", value: "name" },
                { text: "الوصف", value: "description" },
                {
                    text: "الاجراءات",
                    value: "actions",
                    align: "left",
                    sortable: false
                }
            ],
            groups: [],
            roles: [],
            groupRoles: [],
            editedItem: {
                name: "",
                description: "",
                groupRoles: []
            },
            defaultItem: {
                name: "",
                description: "",
                groupRoles: []
            },

            rules: [value => !!value || "Required."]
        };
    },

    created() {
        this.getGroups();
        this.getRoles();
    },

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "إضافة مجموعة" : "تعديل المجموعة";
        },
        likesAllFruit() {
            return this.groupRoles.length === this.roles.length;
        },
        likesSomeFruit() {
            return this.groupRoles.length > 0 && !this.likesAllFruit;
        },
        icon() {
            if (this.likesAllFruit) return "mdi-close-box";
            if (this.likesSomeFruit) return "mdi-minus-box";
            return "mdi-checkbox-blank-outline";
        },
        filterdRoles() {
            var roles = Object.assign([], this.roles);
            if (this.searchRole) {
                return roles.filter(item => {
                    return this.searchRole
                        .toLowerCase()
                        .split(" ")
                        .every(v => item.roleCaption.toLowerCase().includes(v));
                });
            } else {
                return roles;
            }
        }
    },

    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },

    methods: {
        toggle() {
            this.$nextTick(() => {
                if (this.likesAllFruit == true) {
                    this.groupRoles = [];
                } else {
                    this.groupRoles = this.roles.map(obj => obj.id);
                }
            });
        },
        getGroups() {
            this.loading = true;
            this.$http
                .get("account/getGroups")
                .then(response => {
                    this.groups = response.data.data;
                    this.loading = false;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        getRoles() {
            this.$http
                .get("account/getRoles")
                .then(response => {
                    this.roles = response.data.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        getText(i) {
            var result = this.groups.filter(obj => {
                return obj.value == i;
            });
            return result[0].text;
        },

        editItem(item) {
            this.editedIndex = this.groups.indexOf(item);
            this.editedItem = Object.assign({}, item);

            var roles = [];
            for (var i = 0; i < item.applicationRoles.length; i++) {
                roles.push(item.applicationRoles[i].applicationRoleId);
            }

            this.groupRoles = roles;
            this.dialog = true;
        },

        deleteItem(id) {
            this.$GenericService.swalAlertConfirm({}).then(result => {
                if (result.isConfirmed) {
                    this.$GenericService
                        .delete(`account/deleteGroup?id=${id}`)
                        .then(res => {
                            this.getGroups();
                            this.$GenericService.swalAlertSuccess();
                        })
                        .catch(err => {
                            this.$store.dispatch("setSnackbar", {
                                text: ` ${err.response.data.message}`,
                                color: "error"
                            });
                        });
                }
            });
        },

        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },

        save() {
            var val = this.$refs.form.validate();
            if (val) {
                if (this.editedIndex > -1) {
                    var updatedItem = this.groups[this.editedIndex];
                    updatedItem.name = this.editedItem.name;
                    updatedItem.description = this.editedItem.description;
                    updatedItem.groupRoles = this.groupRoles;

                    this.$http
                        .post("account/updateGroup", updatedItem)
                        .then(res => {
                            this.this.getGroups();
                            this.$store.dispatch("setSnackbar", {
                                text: `تم الإضافة بنجاح`
                            });
                        })
                        .catch(e => {
                            console.log(e);
                        });
                    Object.assign(
                        this.groups[this.editedIndex],
                        this.editedItem
                    );
                } else {
                    this.editedItem.groupRoles = this.groupRoles;
                    this.$http
                        .post("account/addGroup", this.editedItem)
                        .then(res => {
                            this.getGroups();
                            this.$store.dispatch("setSnackbar", {
                                text: `تم الإضافة بنجاح`
                            });
                        })
                        .catch(err => {
                            this.$store.dispatch("setSnackbar", {
                                text: ` ${err.data.message}`,
                                color: "error"
                            });
                            console.log(err);
                        });
                }
                this.close();
            }
        }
    }
};
</script>

<style></style>
